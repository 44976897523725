import React from 'react'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'

const ImageCarousel = (props) => {
  const { page, alt, styles } = props
  const classes = useStyles(styles)
  const importAll = (r) => {
    return r.keys().map(r).map(r => r.default)
  }
  const images = {
    offer: importAll(require.context('../../images/img_section_offer', false, /\.(png|jpe?g|svg|webp)$/)),
    area: importAll(require.context('../../images/img_section_area', false, /\.(png|jpe?g|svg|webp)$/)),
    index: importAll(require.context('../../images/img_section_index', false, /\.(png|jpe?g|svg|webp)$/)),
  }
  return (
    <CarouselProvider
      naturalSlideWidth={200}
      naturalSlideHeight={200}
      totalSlides={images[page].length}
      isPlaying
      infinite
      interval={4000}
      style={{ position: 'relative', height: '370px', }}
    >
      <Slider >
        {images[page].map((image, index) => (
          <Slide key={image} index={index}>
            <div className={classes.slide} >
              <img
                className={classes.img}
                src={image}
                alt={`${alt} ${index + 1}`}
              />
            </div>
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  )
}

ImageCarousel.propTypes = {
  page: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  styles: PropTypes.object,
}

const useStyles = createUseStyles({
  slide: {
    marginTop: '0.2em',
    height: '300px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
  },
  img: props => ({
    height: '390px',
    objectFit: 'cover',
    width: '100%',
    float: 'right',
    ...props,
  }),
})

export default ImageCarousel
