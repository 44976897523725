import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

const SeoSectionTitle = props => {
  const { title, subtitle, color, backgroundColor } = props
  const classes = useStyles({ color, backgroundColor })
  return (
    <div className={classes.sectionTitle}>
      <h2>{title}</h2>
      <h3>{subtitle}</h3>
  </div>
  )
}

SeoSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

const useStyles = createUseStyles({
  sectionTitle: props => ({
    textAlign: 'center',
    paddingTop: '5em',
    '& h2': {
      fontSize: '30px',
      color: `${props.color}`,
      fontWeight: 'normal',
    },
    '& h3': {
      color: '#6B7671',
      fontSize: '20px',
      fontWeight: 'normal',
    },
    '&::after': {
      content: "''",
      display: 'block',
      width: '200px',
      height: '4px',
      margin: 'auto',
      backgroundColor: `${props.backgroundColor}`,
    },
  }),
})

export default SeoSectionTitle
